<template>
  <div>
    <b-overlay
      :show="show"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col cols="12">
                    <h4
                      v-if="editable"
                      class="text-primary"
                    >
                      Información de Administrador Gateway
                    </h4>
                    <h4
                      v-if="!editable"
                      class="text-primary"
                    >
                      Formulario de Administrador Gateway
                    </h4>
                    <hr>
                    <br>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group>
                      <h6 class="text-primary">
                        Nombre
                      </h6>
                      <validation-provider
                        #default="{ errors }"
                        name="Nombre"
                        rules="required"
                      >
                        <b-form-input
                          v-model="adminGateway.nombre"
                          :disabled="editable"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="2">
                    <b-form-group>
                      <h6 class="text-primary">
                        Serie
                      </h6>
                      <validation-provider
                        #default="{ errors }"
                        name="Serie"
                        rules="required"
                      >
                        <b-form-input
                          v-model="adminGateway.serie"
                          :disabled="editable"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="2">
                    <b-form-group>
                      <h6 class="text-primary">
                        Protocolo
                      </h6>
                      <validation-provider
                        #default="{ errors }"
                        name="Protocolo"
                        rules="required"
                      >
                        <b-form-input
                          v-model="adminGateway.protocolo"
                          :disabled="editable"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="2">
                    <b-form-group>
                      <h6 class="text-primary">
                        Red
                      </h6>
                      <validation-provider
                        #default="{ errors }"
                        name="Red"
                        rules="required"
                      >
                        <b-form-input
                          v-model="adminGateway.red"
                          :disabled="editable"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="2">
                    <b-form-group>
                      <h6 class="text-primary">
                        Internet
                      </h6>
                      <validation-provider
                        #default="{ errors }"
                        name="Internet"
                        rules="required"
                      >
                        <v-select
                          v-model="adminGateway.internet"
                          :disabled="editable"
                          :options="tiposInternet"
                          label="nombre"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="3">
                    <b-form-group>
                      <h6 class="text-primary">
                        Latitud
                      </h6>
                      <validation-provider
                        #default="{ errors }"
                        name="Latitud"
                        rules="required"
                      >
                        <b-form-input
                          v-model="adminGateway.latitud"
                          :disabled="editable"
                          :state="errors.length > 0 ? false:null"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="3">
                    <b-form-group>
                      <h6 class="text-primary">
                        Longitud
                      </h6>
                      <validation-provider
                        #default="{ errors }"
                        name="Longitud"
                        rules="required"
                      >
                        <b-form-input
                          v-model="adminGateway.longitud"
                          :disabled="editable"
                          :state="errors.length > 0 ? false:null"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="3">
                    <b-form-group>
                      <h6 class="text-primary">
                        Protección Corriente
                      </h6>
                      <validation-provider
                        #default="{ errors }"
                        name="Protección Corriente"
                        rules="required"
                      >
                        <v-select
                          v-model="adminGateway.proteccion"
                          :disabled="editable"
                          label="nombre"
                          :options="proteccion"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="3">
                    <b-form-group>
                      <h6 class="text-primary">
                        Switch Remoto
                      </h6>
                      <validation-provider
                        #default="{ errors }"
                        name="Switch Remoto"
                        rules="required"
                      >
                        <b-form-input
                          v-model="adminGateway.switch"
                          :disabled="editable"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- BOTONES -->
                  <b-col
                    v-if="editable"
                    cols="12"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mt-2 mr-1"
                      @click="changeEditable"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Editar</span>
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="!editable"
                    cols="12"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mt-2 mr-1"
                      @click="validationForm"
                    >
                      <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ getTextButton() }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      type="reset"
                      class="mt-2"
                      @click.prevent="cancel"
                    >
                      <feather-icon
                        icon="SlashIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Cancelar</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <hr>
        </b-form>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormInput, BButton, BFormGroup, BForm, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getAdminGateway, updateCreateAdminGateway } from '@/utils/administrador/gateway'
import { getOpciones } from '@/utils/mantenimiento/opcionesGenericas'

export default {
  components: {
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    BCard,
    BFormInput,
    vSelect,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      required,
      usuario: JSON.parse(localStorage.getItem('userData')),
      adminGateway: {
        nombre: null,
        serie: null,
        protocolo: null,
        red: null,
        internet: null,
        latitud: null,
        longitud: null,
        proteccion: null,
        switch: null,
        companyId: JSON.parse(localStorage.getItem('userData')).idCompany,
        usuarioCreacion: JSON.parse(localStorage.getItem('userData')),
      },
      localization: null,
      tiposInternet: [],
      proteccion: [],
      editable:
          router.currentRoute.params.action !== 'Edit'
          && router.currentRoute.params.action !== 'Create',
      showAdditionalColumn: true,
    }
  },
  async created() {
    await this.loadAdminGateway()
    this.checkAccessMode()
    this.tiposInternet = getOpciones(5)
    this.proteccion = getOpciones(6)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
  },
  methods: {
    async loadAdminGateway() {
      const { id } = this.$route.query
      if (id) {
        this.adminGateway = await getAdminGateway(id)
        if (!this.adminGateway) {
          console.error('Elemento no encontrado')
        }
      }
    },
    async guardarAdminGateway() {
      this.show = true

      if (this.localization === null || this.localization === undefined) {
        mensajeError('Error obteniendo geolocalización, intente nuevamente!')
        return
      }

      this.adminGateway.usuarioCreacion = {
        id: this.usuario.id,
        nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
        name: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
        email: this.usuario.email,
        telefono: '',
      }
      const body = { ...this.adminGateway }
      // eslint-disable-next-line no-unused-vars
      let item

      if (this.$route.query.action === 'Create') {
        const newItem = await updateCreateAdminGateway(body, 2)
        item = { ...newItem }
      } else if (this.$route.query.action === 'Edit') {
        item = body
      }
      await updateCreateAdminGateway(item, 1)
      mensajeInformativo('Guardada', 'Administración de Gateway Almacenada Perfectamente!')
      this.$router.replace('/administrador-gateway').then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Administración de Gateway Almacenada!',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Administración de Gateway Ingresada Correctamente!',
          },
        })
      })
      this.show = false
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.guardarAdminGateway()
        }
      })
    },
    checkAccessMode() {
      const { action } = this.$route.query
      this.editable = action === 'Edit'

      if (!this.editable) {
        this.hideAdditionalColumn()
      }
    },
    hideAdditionalColumn() {
      this.showAdditionalColumn = false
    },
    changeEditable() {
      router.currentRoute.params.action = 'Edit'
      this.editable = false
    },
    getTextButton() {
      if (router.currentRoute.params.action === 'Edit') return 'Actualizar'
      return 'Guardar'
    },
    cancel() {
      const { action } = this.$route.query
      if (action === 'Edit' || action === 'Create'
      ) this.$router.push({ path: '/administrador-Gateway' })
    },
  },
}

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
